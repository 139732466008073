/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #5AC1CB; // blue
	--color2:               #E6B178; // orange
	--color3:               #4AA97F; // green

	// TYPOGRAPHY
	// ---------------

	--text-color:            #212121;
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.875rem;

	--font-family-primary:   "Inter", Helvetica, Arial, sans-serif;
	--font-family-secondary: var(--font-family-primary);
	--font-family-headings:  var(--font-family-secondary);

	--headings-color:        var(--text-color);
	--hr-color:              #DDD;

}